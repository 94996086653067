export const FETCH_PROFILE_INITIATED = 'FETCH_PROFILE_INITIATED'
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS'
export const FETCH_PROFILE_FAILED = 'FETCH_PROFILE_FAILED'

export const UPDATE_PROFILE_INITIATED = 'UPDATE_PROFILE_INITIATED'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_FAILED = 'UPDATE_PROFILE_FAILED'

export const UPDATE_PASSWORD_INITIATED = 'UPDATE_PASSWORD_INITIATED'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_FAILED = 'UPDATE_PASSWORD_FAILED'

export const CLEAR_PROFILE_LOGS = 'CLEAR_PROFILE_LOGS'
