export const FETCH_CLIENT_SECRET_SUCCESS = 'FETCH_CLIENT_SECRET_SUCCESS'
export const SEND_RESET_PASSWORD_REQUEST_INITIATED = 'SEND_RESET_PASSWORD_REQUEST_INITIATED'
export const SEND_RESET_PASSWORD_REQUEST_SUCCESS = 'SEND_RESET_PASSWORD_REQUEST_SUCCESS'
export const SEND_RESET_PASSWORD_REQUEST_FAILED = 'SEND_RESET_PASSWORD_REQUEST_FAILED'

export const VERIFY_RESET_PASSWORD_REQUEST_INITIATED = 'VERIFY_RESET_PASSWORD_REQUEST_INITIATED'
export const VERIFY_RESET_PASSWORD_REQUEST_SUCCESS = 'VERIFY_RESET_PASSWORD_REQUEST_SUCCESS'
export const VERIFY_RESET_PASSWORD_REQUEST_FAILED = 'VERIFY_RESET_PASSWORD_REQUEST_FAILED'

export const RESET_PASSWORD_INITIATED = 'RESET_PASSWORD_INITIATED'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED'
