export const ADD_TICKET_INITIATED = 'ADD_TICKET_INITIATED'
export const ADD_TICKET_SUCCESS = 'ADD_TICKET_SUCCESS'
export const ADD_TICKET_FAILED = 'ADD_TICKET_FAILED'
export const RESET_TICKET_STATE = 'RESET_TICKET_STATE'

export const FETCH_TICKETS_INITIATED = 'FETCH_TICKETS_INITIATED'
export const FETCH_TICKETS_SUCCESS = 'FETCH_TICKETS_SUCCESS'
export const FETCH_TICKETS_FAILED = 'FETCH_TICKETS_FAILED'

export const SELECT_CHANGE_TICKETS_CLOSE_LIST = 'SELECT_CHANGE_TICKETS_CLOSE_LIST'
export const PAGE_CHANGE_LIST_CLOSE_TICKETS = 'PAGE_CHANGE_LIST_CLOSE_TICKETS'
export const RESET_LIST_TICKETS_LIST_STATE = 'RESET_LIST_TICKETS_LIST_STATE'

