export const ADD_SUBSCRIPTION_INITIATED = 'ADD_SUBSCRIPTION_INITIATED'
export const ADD_SUBSCRIPTION_SUCCESS = 'ADD_SUBSCRIPTION_SUCCESS'
export const ADD_SUBSCRIPTION_FAILED = 'ADD_SUBSCRIPTION_FAILED'

export const GET_SUBSCRIPTION_INITIATED = 'GET_SUBSCRIPTION_INITIATED'
export const GET_SUBSCRIPTION_SUCCESS = 'GET_SUBSCRIPTION_SUCCESS'
export const GET_SUBSCRIPTION_FAILED = 'GET_SUBSCRIPTION_FAILED'

export const CONFIRM_CARD_SETUP = 'CONFIRM_CARD_SETUP'
export const CONFIRM_CARD_SETUP_DONE = 'CONFIRM_CARD_SETUP_DONE'
export const RESET_SUBSCRIPTION_STATE = 'RESET_SUBSCRIPTION_STATE'

export const SET_SUBSCRIBED = 'SET_SUBSCRIBED'
export const SET_NOT_SUBSCRIBED = 'SET_NOT_SUBSCRIBED'

export const UPDATE_AUTO_RENEWAL_INITIATED = 'UPDATE_AUTO_RENEWAL_INITIATED'
export const UPDATE_AUTO_RENEWAL_SUCCESS = 'UPDATE_AUTO_RENEWAL_SUCCESS'
export const UPDATE_AUTO_RENEWAL_FAILED = 'UPDATE_AUTO_RENEWAL_FAILED'

export const UPGRADE_PLAN_INITIATED = 'UPGRADE_PLAN_INITIATED'
export const UPGRADE_PLAN_SUCCESS = 'UPGRADE_PLAN_SUCCESS'
export const UPGRADE_PLAN_FAILED = 'UPGRADE_PLAN_FAILED'

export const RESET_SUBSCRIPTION_UPGRADE = 'RESET_SUBSCRIPTION_UPGRADE'
export const RESET_SHOW_PLANS = 'RESET_SHOW_PLANS'
