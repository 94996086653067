import {
  CONTACT_FORM_REQUEST,
  CONTACT_FORM_SUCCESS,
  CONTACT_FORM_FAIL,
  RESET_CONTACT_STATES,
} from '../../actions/actionTypes/contactForm/index'
export const contactFormReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTACT_FORM_REQUEST:
      return { loading: true, ...state }
    case CONTACT_FORM_SUCCESS:
      return { ...state, loading: false, successResponse: true, formData: action.payload }
    case CONTACT_FORM_FAIL:
      return { ...state, loading: false, successResponse: false, error: action.payload }
    case RESET_CONTACT_STATES: {
      return {}
    }

    default:
      return state
  }
}
