export const CUSTOMER_LOGIN_INITIATED = 'CUSTOMER_LOGIN_INITIATED'
export const CUSTOMER_LOGIN_SUCCESS = 'CUSTOMER_LOGIN_SUCCESS'
export const CUSTOMER_LOGIN_FAILED = 'CUSTOMER_LOGIN_FAILED'

export const SET_LOGGED_IN_USER = 'SET_LOGGED_IN_USER'
export const CLEAR_LOGIN_STATE = 'CLEAR_LOGIN_STATE'

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'

export const CUSTOMER_REGISTRATION_INITIATED = 'CUSTOMER_REGISTRATION_INITIATED'
export const CUSTOMER_REGISTRATION_SUCCESS = 'CUSTOMER_REGISTRATION_SUCCESS'
export const CUSTOMER_REGISTRATION_FAILED = 'CUSTOMER_REGISTRATION_FAILED'
export const CLEAR_REGISTER_STATE = 'CLEAR_REGISTER_STATE'
export const FORM_SUBMISSION = 'FORM_SUBMISSION'
export const SET_IS_ACCOUNT_TRUE = 'SET_IS_ACCOUNT_TRUE'
